<template>
  <div class="route">
    <landing-page
      v-if="routeComponent === 'landingPage'"
      @loaded="loaded"
    />
    <multiple-steps
      v-else-if="routeComponent === 'multipleSteps'"
      @loaded="loaded"
    />
    <multiple-steps-add-co-applicant
      v-else-if="routeComponent === 'multipleStepsAddCoApplicant'"
      @loaded="loaded"
    />
    <multiple-steps-continue-application
      v-else-if="routeComponent === 'multipleStepsContinueApplication'"
      @loaded="loaded"
    />
    <single-step
      v-else-if="routeComponent === 'singleStep'"
      @loaded="loaded"
    />
    <credit-card
      v-else-if="routeComponent === 'creditCard'"
      @loaded="loaded"
    />
    <single-step-add-co-applicant
      v-else-if="routeComponent === 'singleStepAddCoApplicant'"
      @loaded="loaded"
    />
    <single-step-continue-application
      v-else-if="routeComponent === 'singleStepContinueApplication'"
      @loaded="loaded"
    />
    <white-label-application
      v-else-if="routeComponent === 'whiteLabelApplication'"
      @loaded="loaded"
    />
    <bluestep
      v-else-if="routeComponent === 'bluestep'"
      @loaded="loaded"
    />
    <opt-out
      v-else-if="routeComponent === 'optOut'"
      @loaded="loaded"
    />
    <opt-out-auto-sbu
      v-else-if="routeComponent === 'optOutAutoSbu'"
      @loaded="loaded"
    />
    <loan-indicator
      v-else-if="routeComponent === 'loanIndicator'"
      @loaded="loaded"
    />
    <loan-calculator
      v-else-if="routeComponent === 'loanCalculator'"
      @loaded="loaded"
    />
    <affiliate-signup
      v-else-if="routeComponent === 'affiliateSignup'"
      @loaded="loaded"
    />
    <calculator
      v-else-if="routeComponent === 'calculator'"
      @loaded="loaded"
    />
    <book-time
      v-else-if="routeComponent === 'bookTime'"
      @loaded="loaded"
    />
    <add-lead
      v-else-if="routeComponent === 'addLead'"
      @loaded="loaded"
    />
    <multiple-steps-pv
      v-else-if="routeComponent === 'multipleStepsPv'"
      @loaded="loaded"
    />
    <single-step-pv
      v-else-if="routeComponent === 'singleStepPv'"
      @loaded="loaded"
    />
    <sme-single-step
      v-if="routeComponent === 'smeSingleStep'"
      @loaded="loaded"
    />
    <mortgage-type-calculator
      v-else-if="routeComponent === 'mortgageTypeCalculator'"
      @loaded="loaded"
    />
    <increase-mortgage-calculator
      v-else-if="routeComponent === 'increaseMortgageCalculator'"
      @loaded="loaded"
    />
    <loan-commitment-calculator
      v-else-if="routeComponent === 'loanCommitmentCalculator'"
      @loaded="loaded"
    />
    <new-mortgage-calculator
      v-else-if="routeComponent === 'newMortgageCalculator'"
      @loaded="loaded"
    />
    <transfer-mortgage-calculator
      v-else-if="routeComponent === 'transferMortgageCalculator'"
      @loaded="loaded"
    />
    <calculator-max-loan-amount
      v-else-if="routeComponent === 'calculatorMaxLoanAmount'"
      @loaded="loaded"
    />
    <loan-commitment
      v-else-if="routeComponent === 'loanCommitment'"
      @loaded="loaded"
    />
    <transfer-mortgage
      v-else-if="routeComponent === 'transferMortgage' || routeComponent === 'increaseMortgage'"
      @loaded="loaded"
    />
    <new-mortgage
      v-else-if="routeComponent === 'newMortgage'"
      @loaded="loaded"
    />
    <zip-code-form
      v-else-if="routeComponent === 'zipCodeForm'"
      @loaded="loaded"
    />
    <insurance-company-teaser
      v-else-if="routeComponent === 'insuranceCompanyTeaser'"
      @loaded="loaded"
    />
    <insurance-company-car-teaser
      v-else-if="routeComponent === 'insuranceCompanyCarTeaser'"
      @loaded="loaded"
    />
    <epi-apply
      v-else-if="routeComponent === 'epiApply'"
      @loaded="loaded"
    />
    <epi-update
      v-else-if="routeComponent === 'epiUpdate'"
      @loaded="loaded"
    />
    <epi-activate
      v-else-if="routeComponent === 'epiActivate'"
      @loaded="loaded"
    />
    <epi-standalone
      v-else-if="routeComponent === 'epiStandalone'"
      @loaded="loaded"
    />
    <epi-standalone-activate
      v-else-if="routeComponent === 'epiStandaloneActivate'"
      @loaded="loaded"
    />
    <epi-standalone-activate-update
      v-else-if="routeComponent === 'epiStandaloneActivateUpdate'"
      @loaded="loaded"
    />
    <epi-telemarketing
      v-else-if="routeComponent === 'epiTelemarketing'"
      @loaded="loaded"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Routes',
    components: {
      LandingPage: () => import(/* webpackChunkName: 'routes/shared/landing-page/default' */
        './_shared/LandingPage'),
      MultipleSteps: () => import(/* webpackChunkName: 'routes/cl/multiple-steps/default' */
        './cl/MultipleSteps'),
      MultipleStepsAddCoApplicant: () => import(/* webpackChunkName: 'routes/cl/multiple-steps/add-co-applicant' */
        './cl/MultipleStepsAddCoApplicant'),
      MultipleStepsContinueApplication: () => import(/* webpackChunkName: 'routes/cl/multiple-steps/continue-application' */
        './cl/MultipleStepsContinueApplication'),
      SingleStep: () => import(/* webpackChunkName: 'routes/cl/single-step/default' */
        './cl/SingleStep'),
      CreditCard: () => import(/* webpackChunkName: 'routes/cl/credit-card/default' */
        './cl/CreditCard'),
      SingleStepAddCoApplicant: () => import(/* webpackChunkName: 'routes/cl/single-step/add-co-applicant' */
        './cl/SingleStepAddCoApplicant'),
      SingleStepContinueApplication: () => import(/* webpackChunkName: 'routes/cl/single-step/continue-application' */
        './cl/SingleStepContinueApplication'),
      WhiteLabelApplication: () => import(/* webpackChunkName: 'routes/cl/white-label-application/default' */
        './cl/WhiteLabelApplication'),
      Bluestep: () => import(/* webpackChunkName: 'routes/cl/bluestep/default' */
        './cl/Bluestep'),
      OptOut: () => import(/* webpackChunkName: 'routes/cl/opt-out/default' */
        './cl/OptOut'),
      OptOutAutoSbu: () => import(/* webpackChunkName: 'routes/cl/opt-out/auto-sbu' */
        './cl/OptOutAutoSbu'),
      LoanIndicator: () => import(/* webpackChunkName: 'routes/cl/loan-indicator/default' */
        './cl/LoanIndicator'),
      LoanCalculator: () => import(/* webpackChunkName: 'routes/cl/loan-calculator/default' */
        './cl/LoanCalculator'),
      AffiliateSignup: () => import(/* webpackChunkName: 'routes/cl/affiliate/signup' */
        './cl/AffiliateSignup'),
      Calculator: () => import(/* webpackChunkName: 'routes/cl/calculator' */
        './cl/Calculator'),
      BookTime: () => import(/* webpackChunkName: 'routes/cl/book-time' */
        './cl/BookTime'),
      AddLead: () => import(/* webpackChunkName: 'routes/cl/add-lead' */
        './cl/AddLead'),
      MultipleStepsPv: () => import(/* webpackChunkName: 'routes/cl/multiple-steps-pv' */
        './cl/MultipleStepsPv'),
      SingleStepPv: () => import(/* webpackChunkName: 'routes/cl/single-step-pv' */
        './cl/SingleStepPv'),
      SmeSingleStep: () => import(/* webpackChunkName: 'routes/sme/single-step' */
        './sme/SingleStep.vue'),
      CalculatorMaxLoanAmount: () => import(/* webpackChunkName: 'routes/mortgage/calculator-max-loan-amount' */
        './mortgage/CalculatorMaxLoanAmount'),
      MortgageTypeCalculator: () => import(/* webpackChunkName: 'routes/mortgage/mortgage-type-calculator' */
        './mortgage/MortgageTypeCalculator'),
      IncreaseMortgageCalculator: () => import(/* webpackChunkName: 'routes/mortgage/increase-mortgage-calculator' */
        './mortgage/IncreaseMortgageCalculator'),
      LoanCommitmentCalculator: () => import(/* webpackChunkName: 'routes/mortgage/loan-commitment-calculator' */
        './mortgage/LoanCommitmentCalculator'),
      NewMortgageCalculator: () => import(/* webpackChunkName: 'routes/mortgage/new-mortgage-calculator' */
        './mortgage/NewMortgageCalculator'),
      TransferMortgageCalculator: () => import(/* webpackChunkName: 'routes/mortgage/transfer-mortgage-calculator' */
        './mortgage/TransferMortgageCalculator'),
      LoanCommitment: () => import(/* webpackChunkName: 'routes/mortgage/loan-commitment' */
        './mortgage/LoanCommitment'),
      TransferMortgage: () => import(/* webpackChunkName: 'routes/mortgage/transfer-mortgage' */
        './mortgage/TransferMortgage'),
      NewMortgage: () => import(/* webpackChunkName: 'routes/mortgage/new-mortgage' */
        './mortgage/NewMortgage'),
      ZipCodeForm: () => import(/* webpackChunkName: 'routes/electricity/zip-code-form' */
        './electricity/ZipCodeForm'),
      InsuranceCompanyTeaser: () => import(/* webpackChunkName: 'routes/insurance/company-teaser' */
        './insurance/CompanyTeaser.vue'),
      InsuranceCompanyCarTeaser: () => import(/* webpackChunkName: 'routes/insurance/company-car-teaser' */
        './insurance/CompanyCarTeaser.vue'),
      EpiApply: () => import(/* webpackChunkName: 'routes/epi/apply' */
        './epi/Apply'),
      EpiUpdate: () => import(/* webpackChunkName: 'routes/epi/update' */
        './epi/Update'),
      EpiActivate: () => import(/* webpackChunkName: 'routes/epi/activate' */
        './epi/Activate'),
      EpiStandalone: () => import(/* webpackChunkName: 'routes/epi/standalone' */
        './epi/Standalone'),
      EpiStandaloneActivate: () => import(/* webpackChunkName: 'routes/epi/standalone/activate' */
        './epi/StandaloneActivate'),
      EpiStandaloneActivateUpdate: () => import(/* webpackChunkName: 'routes/epi/standalone/activate/update' */
        './epi/StandaloneActivateUpdate'),
      EpiTelemarketing: () => import(/* webpackChunkName: 'routes/epi/telemarketing' */
        './epi/Telemarketing')
    },
    computed: {
      ...mapGetters('router', ['getRoute']),
      routeComponent () {
        return this.getRoute?.component
      }
    },
    methods: {
      loaded () {
        this.$emit('loaded')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .route {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
</style>
